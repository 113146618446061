import React from 'react'
import PropTypes from 'prop-types'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'
import { UpdatedButton as Button } from 'components/Forms'

VaultItemCareProviders.propTypes = {
  name: PropTypes.string,
}

function VaultItemCareProviders(props) {
  const { careProviders } = props
  // console.log(`CAREPROVIDERS :: `, careProviders)
  // const user = useUser()

  // const handleSave = (values) => {
  //   const careProviderData = {
  //     petParent: user?.user.name,
  //     // petName: pet?.name,
  //     type: (values?.type) ? values.type : '',
  //     name: (values?.name) ? values.name : '',
  //     phone: (values?.phone) ? values.phone : '',
  //     email: (values?.email) ? values.email : '',
  //     address: (values?.address) ? values.address : '',
  //     notes: (values?.notes) ? values.notes : '',
  //   };
  
  //   // const data = `Pet parent : ${careProviderData.petParent}\n Pet name : ${careProviderData.petName}\n Type: ${careProviderData.type}\n Name: ${careProviderData.name}\n Phone: ${careProviderData.phone}\n Email: ${careProviderData.email}\n Address: ${careProviderData.address}\n Instructions: ${careProviderData.notes}`;
  //   const data = `Pet parent : ${careProviderData.petParent}\n Type: ${careProviderData.type}\n Name: ${careProviderData.name}\n Phone: ${careProviderData.phone}\n Email: ${careProviderData.email}\n Address: ${careProviderData.address}\n Instructions: ${careProviderData.notes}`;
  
  //   const blob = new Blob([data], { type: 'text/plain' });
  
  //   // Create and trigger the download
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = 'Care-Provider-info.txt';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return careProviders.map(({ type, name, phone, email, address, notes }) => (
    <div className={style.wrapper}>
      <Row>
        <RowElement.Title>Type</RowElement.Title>
        <RowElement.Value>{type}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Name</RowElement.Title>
        <RowElement.Value>{name}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Phone</RowElement.Title>
        <RowElement.Value>{phone}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Email</RowElement.Title>
        <RowElement.Value>{email}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Address</RowElement.Title>
        <RowElement.Value>{address}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Notes</RowElement.Title>
        <RowElement.Value>{notes}</RowElement.Value>
      </Row>
      {/* <Button className={style.safeButton} onClick={()=>handleSave({ type, name, phone, email, address, notes })}>
              Save Info
      </Button> */}
    </div>
  ))
}

export default VaultItemCareProviders
