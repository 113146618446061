import React from 'react'
import classNames from 'classnames'
import { Base } from '@pet-notify/ui'

import style from './style.module.css'

type Props = React.ComponentPropsWithoutRef<typeof Base>

function ButtonGroup({ className, ...props }: Props) {
  return <Base className={classNames(className, style.wrapper)} {...props} />
}

export default ButtonGroup
