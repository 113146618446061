import React from 'react'
import { setSeconds, setHours, setMinutes } from 'date-fns'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { create } from 'UserApp/store/alarm/actions'
import AlarmForm from 'UserApp/components/Alarm/Form'

// function makeDateTime(hour, minute, amPM) {
//   let dateTime = new Date()
//   const offset = amPM === 'PM' ? 12 : 0

//   dateTime = setSeconds(dateTime, 0)
//   dateTime = setHours(dateTime, parseInt(hour) + offset)
//   dateTime = setMinutes(dateTime, parseInt(minute))

//   return dateTime
// }

function makeDateTime(hour, minute, amPM) {
  let now = new Date(); // current date and time
  let dateTime = new Date(now); // initialize with current date

  // Parse hour and adjust for 12 AM and 12 PM
  let parsedHour = parseInt(hour);
  if (amPM === 'PM' && parsedHour !== 12) {
    parsedHour += 12;
  } else if (amPM === 'AM' && parsedHour === 12) {
    parsedHour = 0;
  }

  dateTime = setSeconds(dateTime, 0);
  dateTime = setHours(dateTime, parsedHour);
  dateTime = setMinutes(dateTime, parseInt(minute));

  // If the resulting time is earlier than now, set the date to the next day
  if (dateTime <= now) {
    dateTime.setDate(dateTime.getDate() + 1); // roll over to the next day
  }

  return dateTime;
}


class AlarmFormContainer extends React.PureComponent {
  static propTypes = {
    create: PropTypes.func,
  }

  state = {
    isProcessing: false,
  }

  handleSubmit = async (data) => {
    const { create } = this.props
    this.setState({ isProcessing: true })
    const { startTimeHour, startTimeMinute, startTimeAmPM } = data
    const startTime = makeDateTime(
      startTimeHour,
      startTimeMinute,
      startTimeAmPM,
    )

    try {
      await create({ startTime })
    } catch (error) {
      this.setState({ isProcessing: false })
      throw error
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()

    return <AlarmForm {...props} />
  }
}
export default connect(null, { create })(AlarmFormContainer)
