import React from 'react'
import PropTypes from 'prop-types'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'
import { UpdatedButton as Button } from 'components/Forms'

VaultItemTechDevices.propTypes = {
  name: PropTypes.string,
}

function VaultItemTechDevices(props) {
  const { techDevices } = props
  // console.log(`TECHDEVICES :: `, techDevices)
  // const user = useUser()
  
  // const handleSave = (values) => {
  //   const techDeviceData = {
  //     petParent: user?.user.name,
  //     // petName: pet?.name,
  //     type: (values?.type) ? values.type : '',
  //     brand: (values?.brand) ? values.brand : '',
  //     url: (values?.url) ? values.url : '',
  //     username: (values?.username) ? values.username : '',
  //     password: (values?.password) ? values.password : '',
  //     notes: (values?.notes) ? values.notes : '',
  //   };
  
  //   // const data = `Pet parent: ${techDeviceData.petParent}\n Pet name: ${techDeviceData.petName}\n Device type: ${techDeviceData.type}\n Brand: ${techDeviceData.brand}\n URL: ${techDeviceData.url}\n Username: ${techDeviceData.username}\n Password: ${techDeviceData.password}\n Instructions: ${techDeviceData.notes}\n\n Note:'Credentials to access the pet's account in case of emergency.'`;
  //   const data = `Pet parent: ${techDeviceData.petParent}\n Device type: ${techDeviceData.type}\n Brand: ${techDeviceData.brand}\n URL: ${techDeviceData.url}\n Username: ${techDeviceData.username}\n Password: ${techDeviceData.password}\n Instructions: ${techDeviceData.notes}\n\n Note:'Credentials to access the pet's account in case of emergency.'`;
  
  //   const blob = new Blob([data], { type: 'text/plain' });
  
  //   // Create and trigger the download
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = 'Tech-Device-info.txt';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  return techDevices.map(
    ({ type, brand, url, username, password, notes }, index) => (
      <div className={style.wrapper} key={`tech-device-${index}`}>
        <Row>
          <RowElement.Title>Type</RowElement.Title>
          <RowElement.Value>{type}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Brand</RowElement.Title>
          <RowElement.Value>{brand}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>URL</RowElement.Title>
          <RowElement.Value>{url}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Username</RowElement.Title>
          <RowElement.Value>{username}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Password</RowElement.Title>
          <RowElement.Value>{password}</RowElement.Value>
        </Row>
        <Row>
          <RowElement.Title>Notes</RowElement.Title>
          <RowElement.Value>{notes}</RowElement.Value>
        </Row>
        {/* <Button className={style.safeButton} onClick={()=>handleSave({ type, brand, url, username, password, notes })}>
              Save Info
        </Button> */}
      </div>
    ),
  )
}

export default VaultItemTechDevices
