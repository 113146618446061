import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  Label,
  Input,
  Button,
  ErrorText,
  TextArea,
} from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import PhoneInput from 'sharedComponents/Input/Phone'
import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'
import { useParams } from 'react-router-dom';
import { fetchOne } from 'UserApp/store/pets/actions'
import api from 'services/pet-notify-api'

const VALID_EMAIL = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/

CareProviderForm.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  onRemove: PropTypes.func,
}

function CareProviderForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values
  const [pet, setPet] = useState()
  const { id } = useParams()
  // console.log(`PET ID : `,id)
  const user = useUser();
  
  // Function to get pet data
  const getUserPetData = async (id) => {
    try {
      const petData = await api.pets.fetchOne(id); // Fetch pet data
      // console.log(`PET : `,petData)
      setPet(petData); // Set pet data in state
    } catch (error) {
      console.error('Error fetching pet data:', error);
    }
  };

  useEffect(() => {
    // Fetch pet data when the component mounts
    if (id) {
      getUserPetData(id);
    }
  }, [id]); // Dependency array includes `id` to re-run if `id` changes

  useEffect(() => {
    console.log(`USER care provider:`, user);
    console.log(`PET care provider:`, pet);
  }, [user, pet]); // Logs when user or petData changes

  async function handleSubmit(data) {
    const { type, name, email, phone, address } = data

    if (!type || type === '') {
      throw new Error(`Type required`)
    }

    if (!name || name === '') {
      throw new Error(`Name required`)
    }

    if(!phone || phone === '') {
      throw new Error(`Phone required`)
    }

    if(!email || email === '') {
      throw new Error(`Email required`)
    }

    if(!address || address === '') {
      throw new Error(`Address required`)
    }

    if (email && email !== '' && !VALID_EMAIL.test(email)) {
      throw new Error(`Email must be a valid email address`)
    }

    await onSubmit(data)
  }

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  const handleShare = (values) => {
    const careProviderData = {
      petParent: user?.user.name,
      petName: pet?.name,
      type: (values?.type) ? values.type : '',
      name: (values?.name) ? values.name : '',
      phone: (values?.phone) ? values.phone : '',
      email: (values?.email) ? values.email : '',
      address: (values?.address) ? values.address : '',
      notes: (values?.notes) ? values.notes : '',
    };
  
    const data = `Pet parent : ${careProviderData.petParent}\n Pet name : ${careProviderData.petName}\n Type: ${careProviderData.type}\n Name: ${careProviderData.name}\n Phone: ${careProviderData.phone}\n Email: ${careProviderData.email}\n Address: ${careProviderData.address}\n Instructions: ${careProviderData.notes}`;
  
    const blob = new Blob([data], { type: 'text/plain' });
  
    // Create and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Care-Provider-info.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} care provider</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='care-provider-type'>Type</Label>
            <Input
              name='type'
              value={values.type}
              onChange={onChange}
              id='care-provider-type'
            />

            <Label htmlFor='care-provider-name'>Name</Label>
            <Input
              name='name'
              value={values.name}
              onChange={onChange}
              id='care-provider-name'
            />

            <Label htmlFor='care-provider-phone'>Phone</Label>
            <PhoneInput
              name='phone'
              value={values.phone}
              onChange={onChange}
              id='care-provider-phone'
            />

            <Label htmlFor='care-provider-email'>Email</Label>
            <Input
              type='email'
              name='email'
              value={values.email}
              onChange={onChange}
              id='care-provider-email'
            />

            <Label htmlFor='care-provider-address'>Address</Label>
            <Input
              name='address'
              value={values.address}
              onChange={onChange}
              id='care-provider-address'
            />

            <Label htmlFor='care-provider-notes'>Notes</Label>
            <TextArea
              placeholder='Optional'
              name='notes'
              value={values.notes}
              onChange={onChange}
              id='care-provider-notes'
            />
            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add care provider`}
            </Button>
            {isEditing && (
              <Button
                onClick={()=>handleShare(values)}
                small
                className={style.removeButton}
              >
                Share
              </Button>
            )}
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default CareProviderForm
