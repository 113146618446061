import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { Label, Input, FieldGroup, TextArea, Dropdown } from 'components/Forms'
import Text from 'components/Text'
import PhotoUpload from 'UserApp/components/PhotoUpload'
import style from './style.module.scss'

GeneralForm.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isEditing: PropTypes.bool,
}

const UNIT_OPTIONS = ['lbs', 'kg']
const TYPE_OPTIONS = [
  ``,
  `Dog`,
  `Cat`,
  `Bird`,
  `Small animal`,
  `Reptile`,
  `Horse`,
  `Other`,
]

function GeneralForm(props) {
  const { values, onChange, onError } = props

  return (
    <React.Fragment>
      <PhotoUpload
        name='photo'
        onChange={onChange}
        onError={onError}
        value={values.photo}
      />
      <Label htmlFor='pet-name'>Name</Label>
      <Input
        name='name'
        value={values.name}
        onChange={onChange}
        id='pet-name'
      />
      <Label htmlFor='pet-type'>Type</Label>
      <Dropdown
        name='type'
        value={values.type}
        onChange={onChange}
        options={TYPE_OPTIONS}
        id='pet-type'
      />
      <Label htmlFor='pet-breed'>Breed</Label>
      <Input
        placeholder='Optional'
        name='meta.breed'
        value={values.meta.breed}
        onChange={onChange}
        id='pet-breed'
      />

      <FieldGroup>
        <div>
          <Label htmlFor='pet-weight'>Weight</Label>
          <Input
            placeholder='Optional'
            name='meta.weight'
            value={values.meta.weight}
            onChange={onChange}
            id='pet-weight'
          />
        </div>
        <div>
          <Label htmlFor='pet-weightUnit'>Units</Label>
          <Dropdown
            name='meta.weightUnit'
            value={values.meta.weightUnit}
            onChange={onChange}
            options={UNIT_OPTIONS}
            id='pet-weightUnit'
          />
        </div>
      </FieldGroup>

      <Label htmlFor='pet-chipProvider'>Microchip Provider</Label>
      <Input
        placeholder='Optional'
        name='meta.chipProvider'
        value={values.meta.chipProvider}
        onChange={onChange}
        id='pet-chipProvider'
      />

      <Label htmlFor='pet-chipID'>Microchip ID</Label>
      <Input
        placeholder='Optional'
        name='meta.chipID'
        value={values.meta.chipID}
        onChange={onChange}
        id='pet-chipID'
      />

      <Label htmlFor='pet-birthday'>Birthday</Label>
      <DatePicker
        placeholderText='Optional'
        id='pet-birthday'
        maxDate={new Date()}
        dateFormatCalendar='MMMM'
        showYearDropdown
        scrollableYearDropdown
        selected={values.meta.birthday && new Date(values.meta.birthday)}
        className={style.datePicker}
        onChange={(value) => onChange({ name: 'meta.birthday', value })}
        dateFormat='MMMM do, yyyy'
      />

      <Label htmlFor='pet-specialInstructions'>
        Pet Care Alarm Instructions
      </Label>
      <TextArea
        className={style.textarea}
        placeholder="I've encountered an emergency and cannot call for help or care for my pets. Please access my home to care for my dog, Max. His food is in the garage. He gets one scoop of dog food daily and must be let out of the house every 5 hours. In addition, Pet Notify has also provided you with a map of my last known location to confirm I'm safe."
        name='meta.specialInstructions'
        value={values.meta.specialInstructions}
        onChange={onChange}
        id='pet-specialInstructions'
      />
      <Text className={style.note} variant='note'>
      Add pet care instructions for your emergency contacts if your daily check-in or location-based alarm goes off and you cannot care for your pets due to a life emergency. We share this information and your last known location if you do not check-in or call for help.
      </Text>
    </React.Fragment>
  )
}

export default GeneralForm
